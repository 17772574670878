import { useEffect, useState } from "react";
import Statistics from "./stats";
import { database } from "./firebase";
import { onValue, ref } from "firebase/database";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
	TableContainer,
	Table,
	Thead,
	TableCaption,
	Tr,
	Td,
	Tag,
	Th,
	Menu,
	MenuButton,
	MenuItem,
	Spinner,
	Flex,
	Button,
	MenuList,
	Box,
	Tbody,
	Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";

export const ErrorPage = () => {
	const [data, setData] = useState<any>([]);
	const [yearData, setYearData] = useState<any>([]);
	const [monthData, setMonthData] = useState<any>([]);
	const [dayData, setDayData] = useState<any>([]);
	const [loader, setLoader] = useState(false);
	const [selectedDate, setSelectedDate] = useState("");
	const [allDates, setallDates] = useState<any>([]);

	const [env, setEnv] = useState("production");

	useEffect(() => {
		const year: any = [];
		const month: any = [];
		const day: any = [];
		const allData: any = [];
		setLoader(true);

		const handleYear = (item: any) => {
			Object.values(item).map((item: any) => year.push(item));
		};

		const handleMonth = (item: any) => {
			Object.values(item).map((item: any) => month.push(item));
		};

		const handleData = (item: any) => {
			Object.values(item).map((item: any) => allData.push(item));
		};

		const handleDay = (item: any) => {
			if (item !== undefined)
				Object.values(item).map((item: any) => day.push(item));
		};

		onValue(
			ref(
				database,
				`error/${env}/${
					selectedDate?.length > 0
						? dayjs(selectedDate).format("YYYY/MMMM/DD")
						: ""
				}/`
			),
			(snapshot: any) => {
				const val = snapshot?.val();

				if (val === null || val === undefined) {
					setDayData([]);
					setMonthData([]);
					setYearData([]);
					setLoader(false);
					setData([]);
				} else {
					if (selectedDate?.length > 0) {
						handleData(val);

						setData(allData);
						setLoader(false);
					} else {
						const currentYear = dayjs().format("YYYY");

						const x = Object.values(val).map((val: any) => val);

						const _dates = val[currentYear];

						Object.values(_dates).map((total: any) =>
							Object.values(total).map((getDate: any) => handleYear(getDate))
						);

						const currentMonth = dayjs().format("MMMM");

						Object.values(_dates[currentMonth])?.map((total: any) =>
							handleMonth(total)
						);

						const todaysDate = dayjs().format("DD");

						handleDay(_dates[currentMonth][todaysDate]);

						x.map((total: any) =>
							Object.values(total)?.map((getDate: any) =>
								Object.values(getDate).map((item: any) => handleData(item))
							)
						);

						setDayData(day);
						setMonthData(month);
						setYearData(year);

						setData(allData);
						setLoader(false);
						setallDates(
							Array.from(
								new Set(
									allData
										?.filter((item: any) => item?.timeCreated)
										.map((item: any) =>
											dayjs(item?.timeCreated).format("DD/MMMM/YYYY")
										)
								)
							)
						);
					}
				}
			}
		);
	}, [env, selectedDate]);

	return (
		<>
			<Statistics
				year={yearData?.filter((year: any) => year)}
				month={monthData?.filter((month: any) => month)}
				day={dayData?.filter((day: any) => day)}
			/>
			<Box mt={10}></Box>

			<Box padding="0px 20px">
				<Flex alignItems="center" justifyContent={"center"} mx="10">
					<Menu>
						<MenuButton
							as={Button}
							rightIcon={<ChevronDownIcon />}
							textTransform="capitalize"
						>
							{env}
						</MenuButton>
						<MenuList>
							<MenuItem onClick={() => setEnv("staging")}>Staging</MenuItem>
							<MenuItem onClick={() => setEnv("production")}>
								Production
							</MenuItem>
						</MenuList>
					</Menu>

					{allDates?.length > 0 && (
						<Box ml="5">
							<Menu>
								<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
									{selectedDate?.length > 0 ? selectedDate : "Select Date"}
								</MenuButton>
								<MenuList maxHeight={"400px"} overflow="auto">
									<MenuItem onClick={() => setSelectedDate("")}>
										All Dates
									</MenuItem>
									{allDates?.map((item: any) => (
										<MenuItem
											onClick={() =>
												setSelectedDate(dayjs(item).format("DD MMMM, YYYY"))
											}
										>
											{dayjs(item).format("DD MMMM, YYYY")}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
						</Box>
					)}
				</Flex>

				<Box mt={10}></Box>

				<TableContainer border="1px solid #ededf2" borderRadius={"10px"}>
					{loader ? (
						<Box
							display="flex"
							alignItems={"center"}
							justifyContent="center"
							my="10"
						>
							<Spinner
								thickness="4px"
								speed="0.65s"
								emptyColor="gray.200"
								color="blue.500"
								size="xl"
							/>
						</Box>
					) : (
						<Table variant="simple">
							<TableCaption> Showing {data?.length} Errors</TableCaption>
							<Thead>
								<Tr>
									<Th>S/N</Th>
									<Th>User</Th>
									<Th>Workspace </Th>
									<Th>Error Code</Th>
									<Th>Error </Th>
									<Th>Endpoint </Th>
									<Th>Page </Th>
									<Th>Date</Th>
									<Th>Time</Th>
								</Tr>
							</Thead>
							<Tbody>
								{data &&
									data?.reverse()?.map((error: any, idx: number) => (
										<Tr>
											<Td>
												<Text fontSize="13px">{idx + 1}</Text>
											</Td>
											<Td>
												<Text fontSize="13px" fontWeight={700}>
													{error?.loggedInUser && error.loggedInUser.name}
												</Text>
											</Td>
											<Td>
												<Text fontSize="13px" fontWeight={700}>
													{error?.workspace && error.workspace.name}{" "}
												</Text>
											</Td>
											<Td>
												<Text colorScheme={"red"}>
													<Tag
														size={"sm"}
														key={"sm"}
														variant="solid"
														colorScheme="red"
													>
														{error?.error && error.error.code}{" "}
													</Tag>
												</Text>
											</Td>
											<Td>
												<Text fontSize="13px">
													{error?.error && error.error.message}{" "}
												</Text>
											</Td>

											<Td>
												<Text fontSize="13px" fontWeight={500}>
													{error?.url && error.url}
												</Text>
											</Td>
											<Td>
												<Text fontSize="13px" fontWeight={500}>
													{error?.page && error.page}
												</Text>
											</Td>

											<Td>
												<Text fontSize="13px" fontWeight={700}>
													{error?.timeCreated
														? dayjs(error?.timeCreated).format("DD-MM-YYYY")
														: "--- "}
												</Text>
											</Td>
											<Td>
												<Text fontSize="13px" fontWeight={700}>
													{error?.timeCreated
														? dayjs(error?.timeCreated).format("hh:mm a")
														: "---"}
												</Text>
											</Td>
										</Tr>
									))}
							</Tbody>
						</Table>
					)}
				</TableContainer>
			</Box>
		</>
	);
};
