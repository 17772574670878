import { ChakraProvider } from "@chakra-ui/react";
import { ErrorPage } from "./table";

function App() {
	return (
		<ChakraProvider>
			<ErrorPage />
		</ChakraProvider>
	);
}

export default App;
