import dayjs from "dayjs";
import {
	Box,
	chakra,
	SimpleGrid,
	Stat,
	StatLabel,
	StatNumber,
	useColorModeValue,
} from "@chakra-ui/react";

interface StatsCardProps {
	title: string;
	stat: string;
}
function StatsCard(props: StatsCardProps) {
	const { title, stat } = props;
	return (
		<Stat
			px={{ base: 4, md: 8 }}
			py={"5"}
			shadow={"xl"}
			border={"1px solid"}
			borderColor={useColorModeValue("gray.800", "gray.500")}
			rounded={"lg"}
		>
			<StatLabel fontWeight={"medium"}>{title}</StatLabel>
			<StatNumber fontSize={"2xl"} fontWeight={"medium"}>
				{stat}
			</StatNumber>
		</Stat>
	);
}

export default function Statistics({ year, month, day }: any) {
	return (
		<Box maxW="7xl" mx={"auto"} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
			<chakra.h1
				textAlign={"center"}
				fontSize={"4xl"}
				py={10}
				fontWeight={"bold"}
			>
				Error Stats
			</chakra.h1>
			<SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
				<StatsCard
					title={dayjs().format("YYYY")}
					stat={`${year?.length}  Errors`}
				/>
				<StatsCard
					title={"This month"}
					stat={`${month?.length > 0 ? month?.length : 0}  Errors`}
				/>
				<StatsCard
					title={"Today"}
					stat={`${day?.length > 0 ? day?.length : 0}  Errors`}
				/>
			</SimpleGrid>
		</Box>
	);
}
