import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import "firebase/database";

// TODO: Replace the following with your app's Firebase project configuration

const firebaseConfig = initializeApp({
	databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
});
export default firebaseConfig;
export const database = getDatabase(firebaseConfig);
